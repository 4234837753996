.About {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../Pics/about_bg.jpg');
    background-color: rgba(0, 0, 0, .3);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .texts {
        background-color: rgba(0, 0, 0, .4);
        border-radius: .375rem;
    }
}
