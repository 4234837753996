.suggestionBox {
  width: 490px;
}

.suggestion:hover {
  background-color: #5bc0de;
}

.input-left-container {
  width: 60% !important;
}

.input-right-city {
  flex-direction: row;
  justify-content: space-between;
}

.input-right-container {
  width: 40% !important;
}
.input-right-container-header {
  width: 32%;
}

.autocomplete-left-container {
  position: absolute;
  z-index: 10;
  width: calc(60% - 20px);
  -webkit-box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.25);
  transform: translateY(-90px);
}

.autocomplete-left-container-header {
  transform: translateY(0px) !important;
}

.autocomplete-right-container {
  position: absolute;
  z-index: 1000;
  width: calc(100% - 40px);
  transform: translateY(45px);
  background-color: white;
  cursor: pointer;
  text-overflow: "ellipsis";
}

.autocomplete-right-item {
  padding: 5px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.autocomplete-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px !important;
}

::placeholder {
  color: red;
}

@media (max-width: 576px) {
  .autocomplete-left-container {
    transform: translateY(calc(-90px - 24px));
  }
}
