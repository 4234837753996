img {
  display: block;
  width: auto;
  height: auto;
}

.chat-media {
  max-width: calc((100vw - 800px - 100px) / 2);
}

.message-container {
  padding: 7px;
  padding-inline: 15px;
  max-width: calc((100vw - 800px - 100px) / 1.6);
  border-radius: 20px;
  min-width: 30px;
}

.message-container-sent {
  border-bottom-right-radius: 3px;
}

.message-container-received {
  border-bottom-left-radius: 3px;
}

.message-text {
  white-space: pre-wrap;
  font-size: 14px !important;
  font-weight: 500;
}

.message-text-small {
  white-space: pre-wrap;
  font-size: 12px !important;
  font-weight: 500;
}

.message-read {
  white-space: pre-wrap;
  font-size: 12px !important;
  font-weight: 500;
}

.message-date-text {
  font-size: 0.75rem !important;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  text-align: end;
}

.message-grouped-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

.grouped-message-text {
  white-space: pre-wrap;
  font-size: 15px !important;
  font-weight: 500;
}
.grouped-message-date {
  font-size: 14px !important;
  font-weight: 400;
}

.last-message {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.active-conversation-bg {
  background-color: var(--secondary) !important;
}

.scroll-body {
  overflow-y: auto;
  flex: 1;
  height: 1px;
  flex-basis: auto;
  transition: all 5s ease;
}

.animated-input {
  transition: all 0.15s ease-in-out;
}

.reading-animation-1 {
  animation: UpDown 1s linear infinite;
  animation-delay: 0ms;
}

.reading-animation-2 {
  animation: UpDown 1s linear infinite;
  animation-delay: 100ms;
}

.reading-animation-3 {
  animation: UpDown 1s linear infinite;
  animation-delay: 200ms;
}

@keyframes UpDown {
  0%,
  100% {
    transform: translateY(0px);
  }
  33%,
  66% {
    transform: translateY(2px);
  }
}
