.help-card-text {
  transition: max-height 0.3s ease-in-out;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5em;
  text-overflow: ellipsis;
  margin-bottom: 0px;
}

.help-card-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5em;
  max-height: 3em;
  color: initial;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 500;
}

.help-card-title-container {
  display: flex;
  justify-content: space-between;
}

.help-card {
  width: 100%;
  background-color: red;
  background-color: var(--lighter) !important;
}

.help-card-type-text {
  font-size: 19px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #000;
}

.help-card-type-select {
  cursor: pointer;
  padding-block: 10px;
  background-color: var(--lighter) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.help-card-type-select-broker-selected {
  border: 2px solid var(--primary) !important;
}

.help-card-type-select-corporate-selected {
  border: 2px solid var(--warning) !important;
}

.help-card-right {
  width: calc(50% - 15px);
}

.help-card-left {
  width: calc(50% - 15px);
}

.help-cards-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.help-answer-icon {
  width: 20px;
}

.help-answer-icon-svg {
  margin-left: -1px;
}

.help-item-list {
  list-style-type: none;
  padding-inline-start: 0px;
}

.help-item-title {
  font-size: 2rem;
  margin-bottom: 32px;
}

.help-item-subtitle {
  font-weight: bold;
  font-size: 20px;
  margin-block: 24px;
}

.help-item-text {
  font-size: 18px;
}

@media (max-width: 576px) {
  .help-cards-container {
    flex-direction: column;
  }
  .help-card-right {
    width: 100%;
  }

  .help-card-left {
    width: 100%;
  }
}
