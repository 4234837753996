.title-text-center-mobile {
  text-align: center;
  font-size: 2.75rem;
  line-height: 1.5;
}

.intermediary-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 32px;
}

.intermediary-text {
  font-size: 18px;
}

.intermediary-subtitle {
  font-weight: bold;
  font-size: 20px;
  margin-block: 24px;
}

.intermediary-subtitle2 {
  font-weight: bold;
  font-size: 18px;
  margin-block: 24px;
}

@media (max-width: 576px) {
  .title-text-center-mobile {
    text-align: left;
    font-size: 2.2rem;
  }
}
