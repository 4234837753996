/* global custom css */

.bg-title-image {
    max-width: 600px;
    width: 100%;
    padding-inline: 20px;
    position: absolute;
    z-index: 0;
    opacity: 0.6;
    pointer-events: none
}