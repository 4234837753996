.Post {
    p {
        color: black;
        font-weight: 400;

    }

    .image {
        cursor: pointer;
        width: 100%;
        padding-top: 100%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #cccccc;
    }

    .imageOverlay {
        position: absolute;
        background-color: rgba(0, 0, 0, .4);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        cursor: pointer;

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
        }

        &:hover span {
            color: #ffffff !important;
        }
    }

    .actions {
        font-size: 11px;

        i {
            font-size: 13px;
        }

        &:hover {
            color: #5e72e4;
        }
    }

    input {
        border: 0;
        background-color: #F4F5F7;

        &:focus {
            background-color: #F4F5F7;
        }
    }
}
