@import "../Mixin";

.Notifications {
    .dropdown {
        width: 97vw !important;

        @include desktop {
            margin-left: 60px !important;
            width: 420px !important;
        }
    }

    h6 {
        font-size: 13px;
    }

    .notify {
        position: absolute;
        top: -50%;
        left: 50%;
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
        width: 1.5rem;
        height: 1.5rem;
    }
}
