.password-controller-indicator-enabled {
    position: absolute;
    margin-left: -1.75rem;
    transform: translateY(5px);
    height:10px;
    aspect-ratio: 1;
    background-color:var(--blue);
    border-radius: 100px;
}

.password-controller-indicator-disabled {
    position: absolute;
    margin-left: -1.75rem;
    transform: translateY(5px);
    height:10px;
    aspect-ratio: 1;
    background-color:var(--light);
    border-radius: 100px;
}

.password-controller-corporate-indicator-enabled {
    position: absolute;
    margin-left: -1.75rem;
    transform: translateY(5px);
    height:10px;
    aspect-ratio: 1;
    background-color:var(--orange);
    border-radius: 100px;
}

.password-controller-corporate-indicator-disabled {
    position: absolute;
    margin-left: -1.75rem;
    transform: translateY(5px);
    height:10px;
    aspect-ratio: 1;
    background-color:var(--light);
    border-radius: 100px;
}

.label {
    display: flex !important;
    margin:0;
    font-size: .875rem;
}

.signup-modal-button {
    width:100%;
}

.badge-error {
    display: flex;
    white-space: pre-wrap !important
}