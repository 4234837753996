.overflow-wrap {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  
  
  .modal-content-2lg {
      height: 550px;
  }
  
  @media (min-width: 576px) {
    .modal-2lg {
      width: 90% !important;
      max-width: 1200px !important;
    }
  }