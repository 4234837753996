.LandingNavbar {
    .hide {
        opacity: 0;
        visibility: hidden;
        transition: visibility 0s, opacity .15s linear;
    }

    .show {
        opacity: 1;
        visibility: visible;
        transition: visibility 0s, opacity .15s linear;
    }
}
