.suggestion:hover {
    background-color: #5bc0de;
}

.autocomplete-dropdown-container {
    z-index: 10;
    position:absolute;
    background-color: #f4f5f7;
    width:calc(100% - 16px);
    cursor: pointer;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;

}

.autocomplete-item {
    padding: 5px;
    padding-left: 0.75rem;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: 'pointer';
    color: #525f7f
}

.row {
    display: flex;
    flex-direction: row;
}

.item {
    flex-grow: 1;
    width: 180px;
}

.title {
    margin-left: -15px;
}

.container {
    padding-left: 30%;
}

.column {
    overflow-y: scroll;
    height: 400px;
}