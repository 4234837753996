.Dropdown {
  position: relative;

  &:hover .dropdownContent,
  &:focus .dropdownContent {
    display: block;
  }

  @media (hover: none) and (pointer: coarse) {
    .dropdownContent {
      display: block;
    }
  }

  @media (hover: none) and (pointer: fine) {
    .dropdownContent {
      display: block;
    }
  }

  .dropdownIcon {
    min-width: unset;
    position: absolute;
    right: 10px;
    top: 40%;
    display: block;
    transform: rotate(90deg);
  }

  .dropdownContent {
    padding: 5px;
    border-radius: 0.375rem;
    background: #fff;
    box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
    width: 250px;
    display: none;
    position: absolute;
	bottom: 0;
    left: 100%;
    z-index: 99999;
    transition: all 0.5s ease-in-out;
  }

  @media all and (max-width: 768px) {
    .dropdownContent {
      width: 100%;
      left: 0;
    }
  }
}
