.Navbar {
    left: 55px !important;
    right: 0 !important;
    width: auto !important;
    transition: left .15s ease-in-out;
}

.sidebarOpened {
    left: 160px !important;
    right: 0 !important;
    width: auto !important;
    transition: left .15s ease-in-out;
}
