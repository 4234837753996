@import '../Mixin';

.Sidebar {
    .sidebar {
        overflow: unset !important;

        @include desktop() {
            z-index: 3;
            max-width: 250px !important;
            padding-left: 1.5rem !important;
            padding-right: 1.5rem !important;
        }
    }

    .nav {
        @media all and (min-width: 768px) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 35px;
        }
    }

    .BrandIcon {
        display: inline-block !important;

        img {
            width: 50px;
        }
    }

    .icon {
        transform: translateX(-30%);
        margin: 0;
    }
}

.minimized {
    @media all and(min-width: 768px) {
        width: 60px !important;
        transition: width .15s ease-in-out;

        .BrandIcon {
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s, opacity .15s linear;
            display: none !important;
        }

        .itemName {
            visibility: hidden;
            opacity: 0;
            display: none;
            transition: visibility .15s, opacity 0.25s linear;
        }

        .item {
            text-align: center;
        }

        &:hover {
            width: 160px !important;

            .BrandIcon {
                display: inline-block !important;
                visibility: visible;
                opacity: 1;
            }

            .itemName {
                visibility: visible;
                opacity: 1;
                display: block;
            }
        }
    }
}

.expanded {
    @include desktop {
        width: 160px !important;
    }
}
