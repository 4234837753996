.Chat {
    .dropdown {
        cursor: pointer;
        display: none;
    }

    &:hover .dropdown {
        display: unset;
    }

    p {
        display: inline;
        padding: 7px 13px 7px 13px;
    }

    .sent {
        background-color: #5e72e4;
        color: #fff;
        border-radius: 20px 20px 3px 20px;
        max-width: 75%;
        clear: both;
    }

    .received {
        background-color: #f4f5f7;
        border-radius: 20px 20px 20px 3px;
        max-width: 100%;
        clear: both;
    }

    .typing {
        span {
            height: 13px;
            width: 13px;
            display: inline-block;
            margin: 0 1px;
            background-color: #9E9EA1;
            border-radius: 50%;
            opacity: 0.4;
            @for $i from 1 through 3 {
                &:nth-of-type(#{$i}) {
                    animation: blink 1s infinite $i * .333s;
                }
            }
        }
    }

    .imageReceived {
        cursor: pointer;
        max-width: 50%;
        display: block;
        border-radius: 20px 20px 20px 3px;
    }

    .imageSent {
        cursor: pointer;
        max-width: 50%;
        display: block;
        border-radius: 20px 20px 3px 20px;
        float: right;
        clear: both;
    }
}

/* Standard syntax */
@keyframes example {
    0% {
        background-color: red;
        left: 0px;
        top: 0px;
    }
    25% {
        background-color: yellow;
        left: 200px;
        top: 0px;
    }
    50% {
        background-color: blue;
        left: 200px;
        top: 200px;
    }
    75% {
        background-color: green;
        left: 0px;
        top: 200px;
    }
    100% {
        background-color: red;
        left: 0px;
        top: 0px;
    }
}

.Recent {
    &:hover .dropdown {
        display: unset !important;
    }
}

@keyframes blink {
    50% {
        opacity: 1;
    }
}

@keyframes bulge {
    50% {
        transform: scale(1.05);
    }
}
