.suggestionBox {
    width: 490px;
}

.suggestion:hover {
    background-color: #5bc0de;
}

.input-left {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px  !important;;
    background-color: rgba(0, 0, 0, .2) !important;
}


.input-right {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    background-color: rgba(0, 0, 0, .2) !important;
}

.input-left-container {
    width: 60% !important;
}

.input-right-city {
    flex-direction: row;
    justify-content: space-between;
}

.input-right-container {
    width: 40% !important;
}

.autocomplete-left-container {
    position: absolute;
    z-index: 10;
    width: calc(60% - 20px);
    -webkit-box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.25);
    transform: translateY(-90px)
}

.autocomplete-left-container-noblockspace {
    position: absolute;
    z-index: 10;
    width: calc(60% - 20px);
    -webkit-box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.25);
    transform: translateY(-45px)
}

.autocomplete-right-container {
    position: absolute;
    z-index: 1000;
    width: calc(100% - 40px);
    transform: translateY(45px);
    background-color: white;
    cursor: pointer;
    text-overflow: "ellipsis";
}

.autocomplete-right-item {
    padding: 5px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.autocomplete-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 400px !important;
}

@media (max-width: 576px) {
    .autocomplete-left-container {
        transform: translateY(calc(-90px - 24px))
    }

    .autocomplete-left-container-noblockspace {
        transform: translateY(calc(-45px - 24px))
    }

    .input-right-container {
        width: 100% !important;
    }

    .input-right {
        border-radius: 100px !important;
     
    }

    .input-left-container {
        width: 100% !important;
    }

    .input-left {
        border-radius: 100px !important;
    }

    .autocomplete-right-container {
        width: calc(100%);
    }
    
    .autocomplete-left-container-noblockspace {
        width: calc(100% - 30px);
        transform: translateY(-136px)
    }

    .autocomplete-left-container {
        width: calc(100% - 30px);
        transform: translateY(-241px)
    }
}