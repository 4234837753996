.PublishModal {
    max-width: 100%;

    .title {
        font-size: 15px;
    }

    .input {
        min-height: 300px;
        border: 0;
        background-color: #F4F5F7;

        &:focus {
            border: 0;
            background-color: #F4F5F7;
        }
    }

    .images {
        overflow-x: auto;
        white-space: nowrap;
        padding-bottom: 10px;

        .previews {
            background-position: 50% 50%;
            background-repeat: no-repeat;
            height: 100px;
            width: 100px;
            min-height: 100px;
            min-width: 100px;
            background-size: cover;
            transform: rotate(0deg);
            border-radius: .375rem;
            cursor: pointer;
            display: inline-block;

            .discard {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, .6);
                opacity: 0;
                border-radius: .375rem;
                cursor: pointer;

                i {
                    color: rgba(0, 0, 0, .3);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-right: -50%;
                    transform: translate(-50%, -50%);
                    font-size: 20px;
                }
            }

            &:hover .discard {
                opacity: 1;
            }
        }

        .addImage {
            border: 2px dashed #F4F5F7;
            height: 100px;
            width: 100px;
            min-height: 100px;
            min-width: 100px;
            transform: rotate(0deg);
            border-radius: .375rem;
            cursor: pointer;
            display: inline-block;

            .add {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                opacity: 1;
                border-radius: .375rem;
                cursor: pointer;

                &:hover {
                    background-color: rgba(0, 0, 0, .2);
                }

                i {
                    color: rgba(0, 0, 0, .3);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-right: -50%;
                    transform: translate(-50%, -50%);
                    font-size: 20px;
                }
            }
        }
    }
}
