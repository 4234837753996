//
// navbar top (Topbar)
//


.navbar-top {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
    background-color: transparent;
    border: 0;
    box-shadow: none;

    .container,
    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }

    @include media-breakpoint-up(sm) {
        .navbar-brand {
            display: none;
        }
    }

    @include media-breakpoint-down(xs) {
        .navbar-collapse {
            width: 100%;
        }

        .nav-item {
            position: static;

            .dropdown-menu {
                position: absolute;
                width: 94%;
                min-width: auto;
                left: 3%;
                right: auto;
            }
        }
    }

    &.border-bottom {
        &.navbar-dark {
            border-color: rgba(255, 255, 255, .08) !important;
        }

        &.navbar-light {
            border-color: rgba(0, 0, 0, .04) !important;
        }
    }
}

.sidenav-pinned {
    .navbar-top {
        .navbar-brand {
            display: none;
        }
    }
}
