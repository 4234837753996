.Publish {
    h4 {
        font-size: 15px;

        &:hover {
            text-decoration: underline;
            color: #5e72e4 !important;
            cursor: pointer;
        }
    }

    .attachments {
        font-size: 25px;
        cursor: pointer;
    }
}
