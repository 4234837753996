@font-face {
    font-family: 'FontAwesome';
    src: url('../../Vendor/@fortawesome/fontawesome-free/webfonts/fa-solid-900.eot?#iefix') format('embedded-opentype'),
    url('../../Vendor/@fortawesome/fontawesome-free/webfonts/fa-solid-900.woff') format('woff'),
    url('../../Vendor/@fortawesome/fontawesome-free/webfonts/fa-solid-900.ttf') format('truetype'),
    url('../../Vendor/@fortawesome/fontawesome-free/webfonts/fa-solid-900.svg') format('svg');
    font-display: swap;
}


.FileInput {
    input {
        opacity: 0;
    }

    label, label:after {
        position: absolute;
        top: 0;
        right: 0;
        padding: .625rem .75rem;
        line-height: 1.5;
        color: #8898aa;
        background-color: #fff;
    }

    label {
        left: 0;
        height: calc(2.75rem + 2px);
        border: 1px solid #dee2e6;
        border-radius: .25rem;
        box-shadow: 0 3px 2px rgba(233, 236, 239, .05);
    }

    label:after {
        bottom: 0;
        display: block;
        height: 2.75rem;
        font-family: FontAwesome, serif;
        content: "";
        border-left: 1px solid #5e72e4;
        color: #fff;
        background-color: #5e72e4;
        border-radius: 0 .25rem .25rem 0;
        cursor: pointer;
    }
}
