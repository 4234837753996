.BecomeBroker {
    .section {
        height: 100vh;
        position: relative;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
    }

    .section1 {
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../Pics/becomeBroker/section1.jpg');
    }

    .section2 {
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../Pics/becomeBroker/section2.jpg');
    }

    .section3 {
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../Pics/becomeBroker/section3.jpg');
    }

    .section4 {
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../Pics/becomeBroker/section4.jpg');
    }

    .section5 {
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../Pics/becomeBroker/section5.jpg');
    }

    .goDown {
        position: fixed;
        top: 90%;
        left: 50% !important;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        transform: translate(-50%, 0) !important;
        background-color: rgba(0, 0, 0, .3);
        z-index: 3;
        cursor: pointer;
        animation: float 3s ease-in-out infinite;

        i {
            margin-left: 50%;
            transform: translate(-50%, -4%);
        }
    }

    @keyframes float {
        0% {
            box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
            transform: translate(-50% , 0);
        }
        50% {
            box-shadow: 0 25px 15px 0 rgba(0, 0, 0, 0.2);
            transform: translate(-50% , -20px);
        }
        100% {
            box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
            transform: translate(-50% , 0);
        }
    }
}
