.flex-col-mobile {
    flex-direction: row;
}

.pl-4-mobile {
    padding-left: 4px;
}

.title-mobile {
    margin-left: 15px;
}

@media(max-width: 576px) {
    .flex-col-mobile {
        flex-direction: column !important;
    }
    .pl-4-mobile {
        padding-left: 0px;
    }
    .title-mobile {
        margin-left: 0px;
    }

}