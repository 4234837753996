.posts-container {
  padding-top: 80px;
  max-width: 1200px 
}


.post-media {
  -webkit-object-fit: cover;
  object-fit: cover;
  max-width: 100%;
}

.post-media:fullscreen {
  object-fit: contain;
  -webkit-object-fit: contain;
}

.post-media-bottom-shadow {
  position: absolute;
  
  bottom: 0px;
  width: calc(100% - 32px);
  height: 100px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
}



.post-text {
  color: var(--gray-dark);
  font-weight: 400;
  font-size: 0.8rem !important;
  white-space: pre-wrap;
  flex: 1;
  height: 100%;
}

.post-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100% !important;
  max-height: 500px; 
}

.post-image {
  max-width: 100%;
}

.post-video-container {
  display: flex;
  justify-content: center;
  align-items: "center";
}

.post-video {
  max-height: 450px;
  max-width: 100%;
}

.post-media-url-title {
  font-size: 14px;
  color: white;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.post-media-url {
  color: darkgrey;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1; 
  -webkit-box-orient: vertical;
}


.comments::placeholder {
  font-size: 14px;
}

.comments {
  font-size: 14px !important;
}

.highlight {
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  color: var(--primary) !important;
}

.highlight-light {
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  color: var(--lighter) !important;
}
