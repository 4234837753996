.Collapse {
    cursor: pointer;

    i {
        transform: rotate(0deg);
        transition: transform .25s linear;
    }

    .opened {
        transform: rotate(180deg);
        transition: transform .25s linear;
    }
}
