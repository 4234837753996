.DefaultLayout {
    @media all and(min-width: 768px) {
        padding-top: 71px;
        .navbarOpened {
            margin-left: 160px !important;
            transition: margin-left .15s ease-in-out;
        }

        .navbarClosed {
            margin-left: 60px;
            transition: margin-left .15s ease-in-out;
        }
    }
}
