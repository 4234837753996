// Inline code
code {
  font-size: $code-font-size;
  color: $code-color;
  word-break: break-word;

  // Streamline the style when inside anchors to avoid broken underline and more
  a > & {
    color: inherit;
  }
}

// user input typically entered via keyboard
kbd {
  padding: $kbd-padding-y $kbd-padding-x;
  font-size: $kbd-font-size;
  color: $kbd-color;
  background-color: $kbd-bg;
  @include border-radius($border-radius-sm);
  @include box-shadow($kbd-box-shadow);

  kbd {
    padding: 0;
    font-size: 100%;
    font-weight: $nested-kbd-font-weight;
    @include box-shadow(none);
  }
}

// Blocks of code
pre {
  display: block;
  font-size: $code-font-size;
  color: $pre-color;

  // Account for some code outputs that place code tags in pre tags
  code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
}

// Enable scrollable blocks of code
.pre-scrollable {
  max-height: $pre-scrollable-max-height;
  overflow-y: scroll;
}
